<template>
  <div class="page">
    <div class="fab" @click="showAdd = true">
      <van-icon name="plus" color="white" />
    </div>
    <van-empty description="还没有数据哦" v-if="!loading && finished && list.length === 0" style="position: fixed;top: 30vh;left: 0;right: 0;"/>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
          v-model="loading"
          :finished="finished"
          @load="onLoad"
      >
        <van-swipe-cell v-for="item in list" :key="item.deviceId" right-width="60">
          <van-cell  :title="item.name" :label="'今日单量：' + item.todayOrderCount" />
          <template #right>
            <div class="del" @click="onDelete(item)">删除</div>
          </template>
        </van-swipe-cell>
        <!--        <van-cell v-for="item in list" :key="item.deviceId" :title="item.name" :label="'今日单量：' + item.todayOrderCount" />-->
      </van-list>
    </van-pull-refresh>

    <van-popup v-model="showAdd" style="width: 80%;border-radius: 10px;padding-top: 20px">
      <div style="text-align: center;font-weight: bold">添加设备信息</div>
      <van-form @submit="onSubmit">
        <van-field name="deviceId" v-model="form.deviceId" label="设备ID" :rules="[{required: true, message: '请填写'}]"/>
        <van-field name="name" v-model="form.name" label="设备名称"  :rules="[{required: true, message: '请填写'}]"/>
        <van-field name="longitude" v-model="form.longitude" label="设备位置经度"  :rules="[{required: true, message: '请填写'}]"/>
        <van-field name="latitude" v-model="form.latitude" label="设备位置纬度"  :rules="[{required: true, message: '请填写'}]"/>
        <div style="display: flex;justify-content: center;align-items: center">
          <van-button native-type="submit" round type="info" style="width: 200px;margin: 20px 0">提交</van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "cabinet",
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      showAdd: false,
      form: {deviceId: '', name: '', longitude: undefined, latitude: undefined}
    }
  },
  methods: {
    onSubmit(values) {
      console.log(values)
      this.$toast.loading()
      this.$http.post('mock/cabinet', values).then(res => {
        this.$toast.clear(true)
        this.showAdd = false
        if (res.data === 'good') {
          this.onLoad()
        }
      })
    },
    onRefresh() {
      this.onLoad()
    },
    onLoad() {
      this.loading = true
      this.$http.get('mock/cabinet').then(res => {
        this.loading = false
        this.list = res.data
        this.finished = true
        this.refreshing = false
        this.$bus.$emit('data-refresh', {type:'device', amount:this.list.length})
      }).catch(err => {
        console.log(err)
        this.loading = false
        this.finished = true
        this.refreshing = false
      })
    },
    onDelete(item) {
      this.$dialog({title:'确定删除设备 ' + item.name, showCancelButton: true}).then(() => {
        this.$toast.loading({mask: true})
        this.$http.delete('mock/cabinet/' + item.deviceId).then(res => {
          this.$toast.clear(true)
          if (res.data === 'good') {
            this.onLoad()
          }
        })
      }).catch(() => {
        console.log('cancel')
      })
    }
  }
}
</script>

<style scoped>
/deep/ .van-list__finished-text {
  margin-bottom: 100px;
}
.del {
  background-color: red;
  height: 100%;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.fab {
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 16px;
  bottom: 66px;
  z-index: 9999999;
}
.fab:active {
  background-color: brown;
}
.all-room {
  /*height: calc(100% - 50px);*/
  /*overflow-y: scroll;*/
  /*height: 200px;*/
}
</style>
